import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, catchError, defer, map, of, switchMap, tap } from 'rxjs';
import { ErrorType } from 'src/app/auth/auth.component';
import { LoadingOverlayService } from 'src/app/shared/components/loading-overlay/loading-overlay.service';
import { environment } from 'src/environments/environment';

import gcpEnv from '../../../../environments/environment.gcp.json';
import {
  PortfolioSession,
  PortfolioStorage
} from '../../enums/portfolio-storage.enum';
import {
  Data,
  LivPortfolioErrorResponse,
  Model
} from '../../models/liv-portfolio-response-protocol.model';
import { StrapiUserSessionModel } from '../../models/strapi-user-session.model';
import { GeneralService } from '../../services/general.service';
import { SessionService } from '../../services/session.service';
import { StorageService } from '../../services/storage.service';

interface Tokens {
  accessToken: string;
  portalToken: string;
}

interface Tokens {
  accessToken: string;
  portalToken: string;
}

const LOCAL_USER_MAIL = gcpEnv['mail'] || ('' as const);
const LOCAL_USER_PASSWORD = gcpEnv['password'] || ('' as const);

@Injectable({
  providedIn: 'root'
})
export class BeforeLoadGuard {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private sessionService: SessionService,
    private loadingOverlayService: LoadingOverlayService,
    private httpClient: HttpClient,
    private generalService: GeneralService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.loadingOverlayService.open();

    const tokens =
      this.getTokensFromRoute(route) || this.getTokensFromStorage();

    if (!tokens) {
      return this.authenticateLocalUserOrRedirect();
    }

    return this.canAccess(tokens);
  }

  private authenticateLocalUserOrRedirect() {
    if (this.validateLocalOrigin(window.location.href)) {
      return this.authenticateLocalUser();
    } else {
      return of(
        this.router.createUrlTree(['/auth'], {
          queryParams: { error: ErrorType.AccessDenied }
        })
      );
    }
  }

  private getTokensFromRoute(route: ActivatedRouteSnapshot): Tokens | null {
    const accessToken = route.queryParamMap.get('liv-token-pto');
    const portalToken = route.queryParamMap.get('liv-token-ptl');
    return this.areTokensValid(accessToken, portalToken)
      ? { accessToken, portalToken }
      : null;
  }

  private getTokensFromStorage(): Tokens | null {
    const accessToken = this.storageService.get<string>(
      PortfolioStorage.AccessToken
    );
    const portalToken = this.storageService.get<string>(
      PortfolioStorage.PortalToken
    );
    return this.areTokensValid(accessToken, portalToken)
      ? { accessToken, portalToken }
      : null;
  }

  private areTokensValid(
    accessToken: string | null,
    portalToken: string | null
  ): boolean {
    return (
      accessToken !== null &&
      portalToken !== null &&
      typeof accessToken === 'string' &&
      accessToken.trim().length > 0 &&
      typeof portalToken === 'string' &&
      portalToken.trim().length > 0
    );
  }

  private canAccess(tokens: Tokens): Observable<boolean> {
    const { accessToken, portalToken } = tokens;
    const canForward = accessToken !== null && portalToken !== null;

    return defer(() => {
      if (!canForward) {
        return of(canForward);
      }

      this.storageService.save<string>(
        PortfolioStorage.AccessToken,
        accessToken
      );
      this.storageService.save<string>(
        PortfolioStorage.PortalToken,
        portalToken
      );

      return this.setImageToken$().pipe(
        catchError((error: LivPortfolioErrorResponse) =>
          this.handleError(error)
        ),
        map(() => canForward)
      );
    });
  }

  private authenticateLocalUser() {
    return this.httpClient
      .post<{ data: { token: string } }>(`${environment.apiLiv}/login`, {
        login: LOCAL_USER_MAIL,
        password: LOCAL_USER_PASSWORD
      })
      .pipe(
        switchMap((user) =>
          this.fetchUserData(user.data.token).pipe(
            map(({ data }) => {
              return { user, data };
            })
          )
        ),
        switchMap(({ data, user }) =>
          this.handleAuthenticationResponse(data, user)
        ),
        catchError((error: LivPortfolioErrorResponse) =>
          this.handleError(error)
        )
      );
  }

  private handleAuthenticationResponse(
    data: Model<StrapiUserSessionModel>,
    user: { data: { token: string } }
  ) {
    if (!data.attributes) {
      return of(
        this.router.createUrlTree(['/auth'], {
          queryParams: { error: ErrorType.AccessDenied }
        })
      );
    }
    this.storageService.save(
      PortfolioStorage.RunningInDevMode as string,
      new Date().toISOString()
    );
    const { jwt } = data.attributes;
    return this.canAccess({ accessToken: jwt, portalToken: user.data.token });
  }

  private fetchUserData(
    token: string
  ): Observable<Data<StrapiUserSessionModel>> {
    return this.generalService.getAuthToken(token);
  }

  private handleError(error: LivPortfolioErrorResponse): Observable<UrlTree> {
    console.error('An error occurred:', error);
    this.storageService.deleteAll();
    this.loadingOverlayService.remove();
    return of(
      this.router.createUrlTree(['/auth'], {
        queryParams: { error: ErrorType.UnexpectedError }
      })
    );
  }

  private validateLocalOrigin(url: string): boolean {
    const parsedUrl = new URL(url);
    const regex = /^(https?:\/\/)?(localhost|127\.0\.0\.1)(:\d+)?/;
    return regex.test(parsedUrl.origin);
  }

  private setImageToken$() {
    return this.generalService.getImageToken().pipe(
      tap((res) => {
        const imageToken = res.data.attributes.imageToken;
        this.sessionService.save(PortfolioSession.ImageToken, imageToken);
      }),
      catchError((error) => this.handleError(error))
    );
  }
}
