import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  PortfolioSession,
  PortfolioStorage
} from '../enums/portfolio-storage.enum';
import { SessionService } from '../services/session.service';
import { StorageService } from '../services/storage.service';

const uris = {
  noNeedsAuthToken: ['assets', 'storage.googleapis'] as string[],
  needsPortalAuthToken: ['api.inteligenciadevida'] as string[],
  needsImageToken: ['/url/file'] as string[]
} as const;

export function requestNeedsPortalAuthToken(url: string): boolean {
  return requestNeedsUri(uris.needsPortalAuthToken, url);
}

export function requestNoNeedsAuthToken(url: string): boolean {
  return requestNeedsUri(uris.noNeedsAuthToken, url);
}

export function requestNeedsImageToken(url: string): boolean {
  return requestNeedsUri(uris.needsImageToken, url);
}

function requestNeedsUri(uri: string[], url: string): boolean {
  return new RegExp(`${uri.join('|')}`, 'i').test(url);
}
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService,
    private sessionService: SessionService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (requestNoNeedsAuthToken(request.url)) {
      return next.handle(request);
    }

    let accessToken = this.storageService.get<string>(
      PortfolioStorage.AccessToken
    );

    if (requestNeedsImageToken(request.url)) {
      accessToken = this.sessionService.get<string>(
        PortfolioSession.ImageToken
      );
    } else if (requestNeedsPortalAuthToken(request.url)) {
      accessToken = this.storageService.get<string>(
        PortfolioStorage.PortalToken
      );
    }

    return next.handle(
      request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      })
    );
  }
}
